.fc-theme-bootstrap5-shaded  {
  background-color: black;
}

.fc .fc-list-event:hover td {
  background-color: #303030;
}

.fc-list-event {
  background-color: #303030;
}

.fc-daygrid-day-number {
  color: #303030 !important;
}
