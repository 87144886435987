@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: 'black';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:disabled {
  opacity: 1 !important;
  color: #C8CACC;
  cursor: not-allowed;
}

.congrats-bg {
  background-image: url(./assets/congrats.png);
  background-repeat: no-repeat;
  background-size: 70px 70px;
  background-position: right 10px top 10px;
}

.nums-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: small;
  width: 25px;
  height: 25px;
  background-color: #FF6767;
  color: white;
  border-radius: 10px;
  margin-left: 10px;
}

.filepond--credits {
  display: none !important;
}

.ck-powered-by {
  display: none;
}

.image img {
  padding: 20px 0px;
  max-width: 600px;
}