/* body {
    background-color: white;
    font-family: sans-serif;
} */

table.calendar {
    border-collapse: collapse;
    width: 100%;
    position: relative;
}

table.calendar thead {
    background-color: transparent;
    color: black;
    margin-bottom: 3px;
    border-bottom: 2px solid white;
}


table.calendar thead th {
    font-weight: normal;
    padding: 10px 3px;
    text-align: center;
}

table.calendar thead th.bolder {
    font-weight: bold;
}

table.calendar tbody {
    font-size: 0.8em;
    border-bottom: 1px #B9B9B9 solid;
}

table.calendar td {
    text-align: center;
    width: 35px;
    height: 35px;
    position: relative;
    border: 1px solid #ECEDEF;
    min-width: 15px;
    color: white;
}


table.calendar td .half-select {
    cursor: pointer;
    width: 50%;
    height: 35px;
}

.half-select:hover {
    background-color: #DBDFE2;
}

table.calendar td .disabled-part {
    cursor: not-allowed;
    width: 50%;
    height: 35px;
    background-color: #FFB1AC;
}

table.calendar tr:last-child td {
    border-bottom: none;
}

table.calendar td.month-name {
    font-weight: bold;
    text-align: left;
    cursor: default;
    border-left: none;
    color: black;
}

table.calendar td.prev-month,
table.calendar td.next-month {
    color: transparent;
    cursor: default;
    pointer-events: none;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAABZJREFUCB1jYEADmzdv/o8iRA0BoIEAKngPeSAlnXcAAAAASUVORK5CYII=');
}

table.calendar td.week-separator {
    pointer-events: none;
    padding: 0;
    width: 8px;
    min-width: 0;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAABZJREFUCB1jYEADmzdv/o8iRA0BoIEAKngPeSAlnXcAAAAASUVORK5CYII=');
}

table.calendar td.bolder {
    font-weight: bold;
}

table.calendar td.cursor-not-allowed {
    cursor: not-allowed;
}

table.calendar td.weekend {
    color: gray;
}

.day-number {
    color: black;
}

/* Single selected day */
table.calendar td.selected {
    background-color: #016CF4;
    color: white;
    font-weight: bold;
}

.selected .half-select:hover {
    background-color: #4E9AFA;
}

table.calendar td.selected span{
    color: white;
}

/* Selected range */
table.calendar td.range {
    background-color: rgba(255, 69, 0, 0.7);
    font-weight: bold;
    color: white;
}

table.calendar td.range-left {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    overflow: hidden;
    background: orangered;
}

table.calendar td.range-right {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow: hidden;
    background: orangered;
}

div.calendar-controls {
    margin: 5px auto;
    display: table;
    font-size: 25px;
    line-height: 35px;
}

div.calendar-controls div {
    display: inline;
}

div.calendar-controls .current-year {
    margin: 0 30px;
}

div.calendar-controls .control {
    font-weight: bolder;
    color: #323232;
    font-size: 0.8em;
    cursor: pointer;
}

div.calendar-controls .today {
    position: absolute;
    right: 15px;
    line-height: 35px;
    font-size: 0.6em;
    text-transform: uppercase;
}

/* Demo */

div#calendar {
    position: relative;
    border-radius: 5px;
    border: 1px solid #5A5A5A;
    background-color: white;
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* div#root {
    display: table;
    margin: 50px auto;
} */

/* h2,
h3,
h4,
h5 {
    text-align: center;
    color: #404040;
} */

h1 {
    text-align: center;
    color: #B10909;
}

a {
    color: #B10909;
    font-weight: bolder;
    text-decoration: none;
}

a.demoLink {
    text-decoration: underline;
}

div.options {
    border: 1px solid #B9B9B9;
    border-radius: 5px;
    padding: 10px 15px;
    margin-top: 30px;
}

div.options select {
    margin-left: 10px;
}